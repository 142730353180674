.ql-toolbar.ql-snow {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none !important;
    border-bottom: 1px solid #9370db1c !important;
}


.ql-container.ql-snow {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none !important;
}

.quill {
    min-height: 200px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    border-radius: 12px !important;
}

.quill:focus-within {
    outline: 2px solid #096BDE !important;
}

.css-1lyqkne-JoyInput-root,
.css-1nvn3ta-JoyTextarea-root,
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.css-wahqcs-JoySelect-root,
.css-om8c7-JoySelect-root,
.css-1vm0r4s-MuiPaper-root,
.css-uduulw-MuiPaper-root {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    border: none !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.css-uduulw-MuiPaper-root {
    border-radius: 10px;
}

@media (max-width: 768px) {
    .rounded-sm-0 {
        border-radius: 0px !important;
    }
}